@import 'variables';

.MuiTabPanel-root {
  padding: 0 !important;
}
.project-filters {
  overflow: hidden;

  .container {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .project-filters-list {
    height: 160px;
    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.nextScroll {
  $blur: blur(5px);
  $rect: inset(0);

  position: relative;

  &:before {
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: '';
    left: -1.8rem;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 1000;
  }
}

.previousScroll {
  $blur: blur(5px);
  $rect: inset(0);

  position: relative;

  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: '';
    right: -1.8rem;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 1000;
  }
}

.commentCard {
  text-align: left;
  width: 100%;
  margin: 10px 0;
}

.bookmarkContainer:hover .topIndicator {
  visibility: visible;
}

.topIndicator {
  position: absolute;
  visibility: hidden;
}

.topIndicator:before,
.topIndicator:after {
  content: '';
  border-style: solid;
  border-width: 10px;
  width: 0;
  height: 0;
  position: absolute;
  top: -40%;
  left: calc(50% - 10px);
}

.topIndicator:after {
  border-color: transparent transparent #fff;
}

.topIndicator:before {
  margin-top: -1px;
  border-color: transparent transparent #d6d6d6;
}

.project-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 12px;
  font-family: Work, sans-serif;
  font-weight: 600;
  position: absolute;
  box-shadow: 0 4px 6px #00000029;
  top: 10%;
  height: 25px;
  padding-left: 0.5rem;
  padding-right: 0.7rem;

  &.new-project-label {
    color: #fc3997;
    background: #fff3f9;
  }

  &.ending-soon-project-label {
    color: #ff6b00;
    background: #ffd8c4;
  }

  &.success-project-label {
    color: #05a488;
    background: #e7f7fe;
  }

  &.failed-project-label {
    background: #000000a1;
  }

  &.prelaunch-project-label {
    background: #000000a1;
  }
}

.projectDescription {
  font-size: 1.15rem;
  line-height: 2rem;

  h1 {
    font-size: 2rem;
    letter-spacing: -0.015em;
    line-height: calc(1em + 0.25rem);
  }
  h2 {
    font-size: 1.75rem;
    letter-spacing: -0.015em;
    line-height: calc(1em + 0.25rem);
  }
}
.projectCounterparts {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 -30px;
  padding-left: 30px;
  overflow-x: auto;
  width: calc(100vw - 18px);

  @media (min-width: $min-md) {
    display: block;
    margin: 0;
    overflow-x: visible;
    padding: 0;
    width: auto;
  }

  & > * {
    margin: 0 15px 0 0;
    min-width: 80%;

    @media (min-width: $min-md) {
      display: block;
      margin: 15px 0 0;
      min-width: auto;
      overflow-x: hidden;
    }
  }
}

.description-content {
  img {
    max-width: 100%;
  }

  p {
    margin: 0;
  }
}
