@import 'variables';

.app-header {
  justify-content: center;
  background-color: #fff !important;
  color: $basecolor !important;
  box-shadow: none !important;
  height: 66px;
  padding: 0;
  z-index: 1400;

  .MuiToolbar-root {
    padding: 0;
  }
  img {
    display: block;
    max-height: 40px;
  }
}

.menu-link {
  border-bottom: transparent solid 5px;
  height: 61px;
  font-size: 14px;
  color: $basecolor;
  text-decoration: none;
  font-weight: 300;
  padding: 0 10px;

  &.active {
    font-weight: 600;
  }
}

.menu-link div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
