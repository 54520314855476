$basecolor: #000;

$primary: #ffdb44;
$primarydark: #ffcf33;
$secondary: #05A488;
$tertiary: #e05d9c;

$success: #3acb86;
$error: #cb4c3a;

$border: #d6d6d6;

$min-sm: 600px;
$min-md: 900px;
$min-lg: 1200px;
$min-xl: 1536px;
