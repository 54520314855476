@import 'variables';

.homepage {
  //background: transparent radial-gradient(closest-side at 84% 52%, #FFFAE433 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  background:
    url(./../assets/images/gradient-right.jpg) 100% 0 no-repeat,
    url(./../assets/images/gradient-left.jpg) 0 65% no-repeat;
}

.hero {
  margin-top: 20px;

  @media (min-width: 992px) {
    margin-top: 45px;
  }
  .heroWrapper {
    align-items: flex-start;

    @media (min-width: 900px) {
      align-items: center;
    }
  }
  #titleHero {
    font-size: 24px;
    line-height: 30px;
    margin: 0;

    @media (min-width: 992px) {
      font-size: 36px;
      line-height: 44px;
    }
    @media (min-width: 1280px) {
      font-size: 46px;
      line-height: 54px;
    }
  }
  .heroDesc {
    font-size: 15px;
    line-height: 24px;
  }
  #btnHero {
    justify-content: center;
    width: 100%;

    @media (min-width: 992px) {
      width: auto;
    }
  }
}
.popularProjects {
  .projectCard {
    &:nth-child(4) {
      @media (min-width: $min-md) {
        display: none;
      }
      @media (min-width: $min-lg) {
        display: flex;
      }
    }
  }
}
