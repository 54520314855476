@import 'variables';

.app-footer {
  background-color: #054931;
  padding: 60px 0;
  width: 100%;
  margin-top: 50px;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
  }
}
