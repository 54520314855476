@import 'variables';

.projectList {
  background: #fafafa;
}
.action {
  visibility: hidden;
}

.validate {
  background: $success !important;
  color: white !important;
  margin: 10px !important;
  padding: 10px !important;
}
.refuse {
  background: $error !important;
  color: white !important;
  margin: 10px !important;
  padding: 10px !important;
}

.row:hover .action {
  visibility: visible;
}

.admin-menu-link {
  border-right: transparent solid 5px !important;
}
