@import 'variables';

@font-face {
  font-family: 'Commissioner';
  src: url(./font/Commissioner-Regular.woff2);
}
@font-face {
  font-family: 'Commissioner';
  src: url(./font/Commissioner-Bold.woff2);
  font-weight: bold;
}
@font-face {
  font-family: 'Commissioner';
  src: url(./font/Commissioner-Light.woff2);
  font-weight: lighter;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./font/Montserrat-Regular.woff2);
}
@font-face {
  font-family: 'Montserrat';
  src: url(./font/Montserrat-Bold.woff2);
  font-weight: bold;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./font/Montserrat-Light.woff2);
  font-weight: lighter;
}
@font-face {
  font-family: 'Montserrat';
  src: url(./font/Montserrat-ExtraLight.woff2);
  font-weight: 100;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v117/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-Regular.woff2);
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-Medium.woff2);
  font-weight: 500;
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-ExtraBold.woff2);
  font-weight: 800;
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-ExtraLight.woff2);
  font-weight: 200;
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-Light.woff2);
  font-weight: 300;
}
@font-face {
  font-family: 'Work';
  src: url(./font/WorkSans-SemiBold.ttf);
  font-weight: 600;
}

:root {
  --purple: #3b3646;
  --red: #ee4b5a;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v91/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  //display:flex;
  position: relative;
  overflow-x: hidden;
}

#root:has(main.fullheight) {
  min-height: 100vh;
  max-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

main {
  min-height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Work', 'Roboto', sans-serif !important;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ql-video {
  width: 100%;
  height: 420px;

  @media (max-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 920px) {
    height: 400px;
  }

  @media (max-width: 768px) {
    height: 390px;
  }

  @media (max-width: 576px) {
    height: 250px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
}

.MuiTypography-root,
.MuiButtonBase-root,
.MuiTableCell-root,
.MuiInputBase-input,
.MuiStepLabel-label,
.MuiChip-label,
.MuiFormLabel-root {
  font-family: 'Work', 'Roboto', sans-serif !important;
}
.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1248px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;

  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.main-content {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
}

.btn-primary {
  font-family: 'Work', 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
  line-height: 16px;
  padding: 10px 30px !important;
  white-space: nowrap;
  height: 2.5rem;

  &:hover {
    background-color: $primarydark !important;
  }
  &:disabled {
    background-color: #ffdb4480 !important;
    color: #00000061;
    cursor: default;
  }

  &--small {
    padding: 5px 10px !important;
    font-size: 10px !important;
    height: 2rem;
  }
}
.btn-grey {
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 30px !important;
  font-weight: 500 !important;
  opacity: 1;
  color: $basecolor !important;
  padding: 10px 20px !important;
  text-transform: none !important;

  &--small {
    padding: 5px 10px !important;
    font-size: 10px !important;
    height: 2rem;
  }
}

.user_avatar:hover {
  text-decoration: underline !important;
}

.link:hover {
  text-decoration: underline !important;
}

.btn-simple {
  background: #fff 0 0 no-repeat padding-box !important;
  opacity: 1;
  color: $basecolor !important;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  border: 2px dashed #0000001a;
  border-radius: 10px;
}

.file-preview {
  margin: 0 10px;
}

.ql-editor {
  min-height: 10vh;
}

.typeWarning {
  font-style: italic;
  font-weight: lighter;
  color: #c4c4c4;
}

.dropzone p {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.resourceUpload {
  border-radius: 10px;
  background: #fff;
}

.project-description img {
  max-width: 100%;
}

.actualite-description img {
  max-width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.flexDisplay .MuiCollapse-wrapperInner {
  display: flex;
}

.formation-time {
  background: rgba(0, 0, 0, 0.05) 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 6px 10px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: row;
}

.formation-elearning {
  background: rgba(224, 93, 156, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 6px 10px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: row;
  color: rgba(224, 93, 156, 1);
}

.formation-presentiel {
  background: rgba(248, 176, 0, 0.15) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 6px 10px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: row;
  color: rgba(226, 160, 0, 1);
}

.formation-hybride {
  background: rgba(45, 171, 97, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 6px 10px;
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: row;
  color: rgba(45, 171, 97, 1);
}
.filter-button:hover {
  background-color: #05a488 !important;
}

.active-category {
  opacity: 1;
}

.inactive-category {
  opacity: 0.3;
}

.btn-no-hover:hover {
  background-color: transparent !important;
}

.inactive-step circle {
  color: #f4f4f4 !important;
  fill: #f4f4f4 !important;
}

.inactive-step text {
  color: #000 !important;
  fill: #000 !important;
}

.active-step text {
  color: #fff !important;
  fill: #fff !important;
}

.ql-toolbar {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.delete-icon:hover {
  background-color: rgb(230, 230, 230) !important;
}

.title-with-color span {
  color: #05a488;
}

.container-1-2 {
  padding: '1% 2%';
}

.container-1-2 img {
  max-width: 100%;
  max-height: 500px;
  width: auto;
}

.display-sd {
  display: none;
}

@media screen and (max-width: 1250px) {
  .container-1-2 {
    padding: '1%';
  }
}

@media screen and (max-width: 1024px) {
  .sd-center {
    justify-content: center;
  }

  .text-center-mobile {
    text-align: center;
  }

  .display-sd {
    display: flex;
  }

  .hide-sd {
    display: none;
  }
}

/*Snackbar newsletter*/
.MuiSnackbarContent-root {
  background-color: #fff !important;
  font-family: 'Work', sans-serif !important;
  color: black !important;
}
